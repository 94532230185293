// pages/places.js
import React from "react";
import { graphql } from "gatsby";
import '../styles/global.css';
import Layout from "../components/layout"
import Coupon from "../components/coupon";

const PlacesPage = ({ data }) => {
  const place = data.googlePlacesPlace;
  const reviews = place.childrenGooglePlacesReview.map(r => {
    const count = [];
    for(let i = 1; i <= r.rating; i++) {
      count.push(i);
    }
    return (
    <div class="bg-white rounded-lg p-6">
      <img class="h-16 w-16 rounded-full mx-auto" alt='A Customer Avatar from a Rayburns Roofing Review on Google Places' src={r.profile_photo_url} />
      <strong class="text-lg">
        {r.author_name}
      </strong>
      <div class="rating">
        {count.map(x => {
          return <span style={{color: 'gold'}}>☆</span>
        })}
      </div>
      <p class="text-gr">{`${r.text.substring(0, 250)} ...`}</p>
    </div>
  )});
  return (
    <Layout>
      <div class="mx-auto">
        <div class="slider">
          <div class="slides">
            {reviews.map((x, i) => (
              <div id={`slide-${i + 1}`}>
                {x}
              </div>
            ))}
          </div>
        </div>
      </div>
      <Coupon />
    </Layout>
  );
};

export const query = graphql`
  query {
    googlePlacesPlace {
      name
      rating
      childrenGooglePlacesReview {
        author_name
        text
        rating
        profile_photo_url
      }
      user_ratings_total
    }
  }
`;

export default PlacesPage;